/* src/App.css */
.background {
  position: relative;
  min-height: 100vh; /* Altere de height para min-height para permitir rolagem */
  width: 100%;
  background-color: #000000; /* Cor de fundo, altere conforme necessário */
  overflow-y: auto; /* Permite rolagem vertical */
  z-index: 0;
}

